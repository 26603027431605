.chatBoxCont {
    /* height: 100%; */
    /* border: 2px solid yellow; */
    position: relative;
    z-index: 1;
    height: calc(100vh - 70px);
}



.chatBoxCont .messageCont {
    /* height: 90%; */
    /* height: 80vh; */
    height: calc(100% - 60px);
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-items: end;
    /* border: 2px solid blue; */
}

.chatBoxCont .messageCont::-webkit-scrollbar {
    width: 0;
    display: none;
}

.chatBoxCont .messageCont .msgDiv {
    /* min-width: 40%; */
    max-width: 70%;
    width: fit-content;
    /* max-width: fit-content; */
    margin-bottom: 10px;
    padding: 8px 10px;
    color: var(--text-color);
    /* font-family: 'Roboto', sans-serif; */
    /* font-family: "Noto Sans", sans-serif; */
    font-family: var(--msg-font);
    font-size: var(--msgDiv-font-size);
    font-weight: 300;
    /* min-height: 40px; */
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    border: var(--msg-border-color);
}

.chatBoxCont .messageCont .msgDiv span {
    object-fit: cover;
    max-width: 100%;
    margin-bottom: 10px;
}

.chatBoxCont .messageCont .userMsgDiv {
    margin-left: auto;
    /* text-align: justify; */
    border-radius: 8px 8px 0px 8px;
    /* background-color: #dbe5f3;
    color: black; */
    background-color: var(--userMsgDiv-background-color);
    color: var(--userMsgDiv-text-color);
    /* background: rgb(221,8,150);
    background: linear-gradient(120deg, rgba(221,8,150,1) 60%, rgba(87,25,166,1) 100%); */
}

.chatBoxCont .messageCont .botMsgDiv {
    /* text-align: justify; */
    border-radius: 0px 8px 8px 8px;
    /* background-color: #eeeeee;
    color: black; */
    background-color: var(--botMsgDiv-background-color);
    color: var(--botMsgDiv-text-color);
}

.chatBoxCont .messageCont .msgDiv .lbImg {
    object-fit: cover;
    max-width: 100%;
}

.chatBoxCont .messageCont .tag {
    /* color: #ffffffc7; */
    color: var(--tag-text-color);
    /* font-family: 'Roboto', sans-serif; */
    font-family: var(--main-font);
    font-size: var(--tag-font-size);
    font-weight: 100;
    align-items: center;
}

.chatBoxCont .messageCont .userTag {
    display: block;
    margin-left: auto;
    text-align: right;
    margin-right: 2px;
}

.chatBoxCont .messageCont .botTag {
    text-align: left;
    margin-left: 2px;
}

.chatBoxCont .formInput {
    /* padding: 0.5rem 1.2rem; */
    padding: 10px;
    /* margin-top: 12px !important; */
    /* width: 97.5%; */
    margin: auto;
    display: flex;
}

.chatBoxCont .formInput .inputCont {
    width: 100%;
    /* width: 92%; */
    outline: none;
    height: 40px;
    border: unset;
    /* background: #edeef6 !important; */
    background: var(--input-background-color) !important;
    color: var(--input-text-color);
    border-radius: 5px 0px 0px 5px;
    padding: 8px;
    border-left: var(--input-border-color);
    border-top: var(--input-border-color);
    border-bottom: var(--input-border-color);
}

.chatBoxCont .formInput .button {
    /* position: absolute;
    right: 10px;
    height: 40px; */
    outline: none;
    border: unset;
    /* background: #edeef6 !important; */
    background: var(--input-background-color) !important;
    border-radius: 0px 5px 5px 0px;
    
    border-right: var(--input-border-color);
    border-top: var(--input-border-color);
    border-bottom: var(--input-border-color);
}

.chatBoxCont .formInput .button img{
    filter: invert(44%) sepia(0%) saturate(311%) hue-rotate(139deg) brightness(80%) contrast(86%);
}

.chatBoxCont .formInput span {
    position: absolute;
    font-size: 12px;
    bottom: 50px;
    right: 15px;
    color: #9c9898dd;
    /* font-family: "Noto Sans", sans-serif; */
    /* font-family: "Roboto", sans-serif; */
    font-family: "Raleway", system-ui;
    /* font-family: "Old Standard TT", system-ui; */
}

/* .chatBoxCont .formInput::before {
  content:'';
  position:fixed;
  z-index:-1;
  top: 87vh;
  left:0;
  right:0;
  bottom: 0;
  background: black;
  background-repeat: no-repeat;
} */

.chatBoxCont .messageCont .optionsCont {
    /* max-width: 70%; */
    margin-bottom: 8px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: var(--option-font);
    font-size: var(--option-font-size);
    font-weight: 300;
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
    text-align: justify;
    color: var(--option-text-color);
}

.chatBoxCont .messageCont .optionsCont span {
    padding: 0px 10px;
    margin: 3px 5px;
    cursor: pointer;
    /* border: 1px solid black; */
    /* border: 1px solid #2e2e2e59; */
    border: 1px solid var(--option-border-color);
    /* border-radius: 20px; */
    border-radius: 4px;
    background-color: var(--option-background-color);
}

.chatBoxCont .messageCont .optionsCont span:hover {
    /* background-color: #dbe5f3; */
    background-color: var(--option-hovered-background-color);
    border: 1px solid var(--option-hovered-border-color);
}

.chatBoxCont .messageCont .optionsCont2 {
    /* max-width: 70%; */
    margin-bottom: 8px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: var(--heading-font);
    font-size: var(--option-font-size);
    font-weight: 300;
    display: flex;
    white-space: nowrap;
    text-align: justify;
    color: var(--option-text-color);
    overflow-x: scroll;
}

.chatBoxCont .messageCont .optionsCont2::-webkit-scrollbar {
    width: 0;
    display: none;
}

.chatBoxCont .messageCont .optionsCont2 .optionDiv {
    margin: 2px 5px;
    border: 3px solid var(--option-border-color);
}

.chatBoxCont .messageCont .optionsCont2 span {
    padding: 0px 10px;
    margin: 3px 5px;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
}

.chatBoxCont .messageCont .optionsCont2 .optionImg {
    aspect-ratio: 1 / 2;
    height: 160px;
    width: 140px;
}

.chatBoxCont .messageCont .newFetureBtn {
    padding: 0px 10px 2px 10px;
    margin: 3px 0px;
    border-radius: 5px;
    cursor: pointer;

    font-family: var(--main-font);
    font-size: var(--heading-font-size);
    font-weight: 300;
    white-space: nowrap;
    color: var(--text-color2);
    background-color: var(--newFeature-background-color);
}

.chatBoxCont .messageCont .newFetureBtn:hover {
    background-color: var(--newFeature-hovered-background-color);
}

.optionsScrollView {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 35%;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scroll-behavior: smooth;
    /* gap: 15px; */
    width: 100%;
    /* margin-bottom: 10px; */
    margin-bottom: 0px;
}

.optionsScrollView::-webkit-scrollbar {
    width: 0;
    display: none;
}

.snaps-inline {
    scroll-snap-type: inline mandatory;
    /* scroll-padding-inline: 15px; */
}

.snaps-inline>* {
    scroll-snap-align: start;
}

.optionCardCont {
    border: 1px solid var(--card-border-color);
    border-radius: 8px;
    /* overflow: hidden; */
    cursor: pointer;
    /* box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 1px 1px var(--card-boxshadow);
    margin: 5px 6px;
    /* padding: 12px; */
    position: relative;
    transition: 0.2s ease-out;
    
    /* display: grid;
    grid-template-rows: min-content; */
    /* display: flex;
    flex-direction: column; */
    user-select: none;
    background-color: var(--option-title-background-color);
}

.optionCardCont:hover {
    border: 1.5px solid var(--card-hovered-border-color);
}

.optionCardCont img {
    width: 100%;
    height: auto;
    aspect-ratio: 2/3;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    user-select: none;
}

.optionCardCont .option-details {
    user-select: none;
}

.optionCardCont .option-details .option-title {
    border-radius: 0px 0px 8px 8px;
    /* background-color: #f5f5f5e0; */
    /* color: #7c3d55d8; */
    /* font-weight: bold; */
    padding: 4px;
    margin: 0;
    /* font-family: 'Montserrat', sans-serif; */
    /* font-family: 'Roboto', sans-serif; */
    font-family: var(--option-font);
    letter-spacing: 0.5px;
    white-space: nowrap;

    overflow-x: scroll;
    display: flex;
    font-size: var(--option-title-font-size);
    justify-content: center;
}

/* .optionCardCont:hover .option-details .option-title:hover { */
.optionCardCont:hover{
    background-color: var(--option-title-hovered-background-color) !important;
}

.optionCardCont .option-details .option-title::-webkit-scrollbar {
    width: 0;
    display: none;
}

::placeholder {
    color: var(--input-placeholder-text-color);
    opacity: 1;
}

::-ms-input-placeholder {
    color: var(--input-placeholder-text-color);
}

/* @media only screen and (max-height: 600px) {
    .chatBoxCont .messageCont {
        height: 76vh;
    }
}

@media only screen and (max-height: 800px) {
    .chatBoxCont .formInput {
        margin-top: 7px !important;
    }

    .chatBoxCont .formInput::before {
        top: 85vh;
    }
}

@media only screen and (min-height: 890px) {
    .chatBoxCont .formInput {
        margin-top: 12px !important;
    }
}

@media only screen and (min-height: 1050px) {
    .chatBoxCont .messageCont {
        height: 86.5vh;
    }
} */

@media only screen and (max-width: 500px) {
    .chatBoxCont {
        height: calc(90vh - 70px);
    }
    .chatBoxCont .messageCont {
        /* height: 70vh; */
        /* height: calc(100vh - 130px); */
        /* height: 80vh; */
        /* height: 75vh; */
        height: calc(100% - 60px);
    }
}