.smileCont {
    display: flex;
    justify-content: center;
    align-items:flex-start;
    margin-bottom: 5px;
}

.smileCont .smileSvg {
    margin-right: 13px;
    width: 25px;
    cursor: pointer;
}

.smileCont .s1{
    /* border: 1px solid #F514A9; */
    /* border: 1px solid black; */
    background-color: #F514A9;
    border-radius: 50%;
    height: 25px;
}
.smileCont .s2 {
    border-radius: 50%;
    border: 1px solid black;
    height: 25px;
}

@media screen and (max-width: 500px) {
    .smileCont .smileSvg {
        margin-right: 13px;
        width: 26px;
        cursor: pointer;
    }
}