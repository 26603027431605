.productDetailCardCont {
    display: flex;
    border: 1px solid var(--product-detail-card-border-color);
    border-radius: 8px;
    overflow: hidden;
    width: 800px;
    box-shadow: var(--product-detail-card-boxshadow);
    /* font-family: 'Montserrat', sans-serif; */
    font-family: var(--product-detail-font);
    /* user-select: none; */
    background: var(--product-detail-background-color);

    /* max-height: 35rem; */
    height: 32rem;
}

.productDetailCardCont .imgBox {
    flex: 1;
    /* overflow: hidden; */
    width: 100px; /*dummy due to problem happning on desktop view in img slider*/
}

.productDetailCardCont .imgBox img {
    width: 100%;
    height: 100%;
}

.productDetailCardCont .product-details {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    overflow: auto;
    text-align: justify;
}
.productDetailCardCont .product-details::-webkit-scrollbar {
    width: 0;
}
.productDetailCardCont .product-details .descbtn {
    color: #aeaeae !important;
    font-size: 15px;
    padding: 0 0 2px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: Raleway;
    display: inline-block;
    width: fit-content;
}
/* .productDetailCardCont .product-details .descbtn:hover, .productDetailCardCont .product-details .descbtn:active { */
.productDetailCardCont .product-details .descbtn:hover {
    border-bottom: 2px solid black;
    color: black !important;
}

.productDetailCardCont .product-details .product-title {
    font-size: var(--pdc-title-font-size);
    margin-bottom: 8px;
    /* font-weight: bold; */
    font-family: var(--product-title-font);
    font-style: var(--product-title-font-style);
    font-weight: 400;
}

.productDetailCardCont .product-details .product-description {
    color: var(--pdc-text-color);
}

.productDetailCardCont .product-details .product-price {
    font-weight: bold;
    margin-top: 3px;
    /* color: #a65877; */
    color: var(--text-color2);
}


.productDetailCardCont .product-details a {
    text-align: center;
}

.productDetailCardCont .product-details .btn {
    font-size: var(--pdc-detail-font-size);
    letter-spacing: 2px;
    text-transform: uppercase;
    appearance: none;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    border: none !important;
    cursor: pointer;
    margin: 4px 0px;
    transition: 0.8s ease-in-out;
    position: relative;
}

.productDetailCardCont .product-details .btn1 {
    /* background: rgb(238, 228, 214);
    color: rgb(42, 41, 43); */
    background-color: var(--btn-background-color);
    color: var(--btn-text-color);
    border: 1px solid var(--btn-border-color) !important;
    width: 100%;
}

.productDetailCardCont .product-details .btn1:hover {
    /* background: rgb(235, 210, 176); */
    background: var(--btn-hovered-background-color);
    color: var(--btn-hovered-text-color);
    border: 1px solid var(--btn-hovered-border-color) !important;
}

.productDetailCardCont .product-details .btn2 {
    background: #7C3D55;
    color: #EEE4D6;
}


.productDetailCardCont .product-details .btn2:hover {
    background: #a65877;
}

.productDetailCardCont .product-details .variantTitle {
    color: var(--text-color2);
    margin: 5px 0px;
    font-weight: bold;
}

.productDetailCardCont .product-details .listItemCont {
    display: flex;
    width: 100%;
    margin: unset;
    list-style: none;
    padding: unset;
    overflow-x: scroll;
}

.productDetailCardCont .product-details .listItemCont::-webkit-scrollbar {
    width: 0;
  } 

.productDetailCardCont .product-details .listItemCont .listItem {
    display: flex;
    justify-content: center;
    /* min-width: 56px; */
    /* padding: 11px 18px 13px; */
    padding: 5px 18px;
    margin-right: 4px;
    /* border: 1px solid var(--pdc-size-border-color); */
    /* border-radius: 4px; */
    background: var(--pdc-size-background-color);
    color: var(--pdc-size-text-color);
    text-align: center;
    cursor: pointer;
    transition: background .2s ease;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Raleway;
}

.productDetailCardCont .product-details .listItemCont .listItem:hover {
    border: 1px solid var(--pdc-size-hovered-border-color);
    /* border-radius: 4px; */
    /* background: #f5f1ec; */
    background: var(--pdc-size-hovered-background-color);
}

.productDetailCardCont .product-details .listItemCont .selectedSize {
    border: 1px solid var(--pdc-size-hovered-border-color);
    /* border-radius: 4px; */
    /* background: #f5f1ec; */
    background: var(--pdc-size-hovered-background-color);
}

@media only screen and (max-width: 950px) {
    .productDetailCardCont {
        flex-direction: column;
        max-width: 90vw;
        max-height: 80vh;
        overflow-y: scroll;

        height: unset;
    }
    .productDetailCardCont .imgBox {
        /* overflow: scroll; */
        width: unset;
    }
    .productDetailCardCont .product-details {
        overflow: unset;
    }
}