.questionCardCont {
    border: 1px solid var(--card-border-color);
    border-radius: 8px;
    box-shadow: 0px 2px 20px var(--card-boxshadow);
    margin: 5px 15px;
    padding: 12px;
    position: relative;
    transition: 0.2s ease-out;

    display: flex;
    flex-direction: column;
    background-color: transparent;
}

.questionCardCont .question {
    user-select: none;
    line-height: 1.5rem;
    border-radius: 8px;
    background-color: var(--card-que-background-color);
    color: var(--card-que-text-color);
    font-weight: bold;
    padding: 10px;
    font-size: var(--card-que-title-font-size);
    margin: 0;
    font-family: var(--card-que-font);
    letter-spacing: 0.5px;
}

.questionCardCont .optionCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.questionCardCont .optionCont .option-item {
    user-select: none;
    border-radius: 8px; 
    background-color: var(--card-opt-background-color);
    color: var(--card-opt-text-color);
    padding: 3px 8px;
    font-size: var(--card-que-title-font-size);
    font-weight: bold;
    font-family: var(--card-que-font);
    letter-spacing: 0.5px;   
    line-height: 2.4rem;
    margin: 4px;
}

.questionCardCont .optionCont .option-item:hover {
    background-color: var(--card-opt-hovered-background-color);
}