.questionCardCont {
    border: 1px solid rgba(222, 219, 219, 0.57);
    border-radius: 8px;
    box-shadow: 0px 2px 20px rgba(80, 77, 77, 0.2);
    margin: 5px 15px;
    padding: 12px;
    position: relative;
    transition: 0.2s ease-out;

    display: flex;
    flex-direction: column;
    background-color: transparent;
}

.questionCardCont .question {
    user-select: none;
    line-height: 1.5rem;
    border-radius: 8px;
    background-color: #ffffff24;
    color: white;
    font-weight: bold;
    padding: 10px;
    font-size: 1rem;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
}

.questionCardCont .optionCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.questionCardCont .optionCont .option-item {
    user-select: none;
    border-radius: 8px; 
    background-color: #ffffff24;
    color: white;
    padding: 3px 8px;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;   
    line-height: 2.4rem;
    margin: 4px;
}

.questionCardCont .optionCont .option-item:hover {
    background-color: #ffffff42;
    color: white;
}