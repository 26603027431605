/* .preSearchCont, .searchCont {
  display: flex;
  margin: 10px;
  position: relative;
  user-select: none;
  transition: all 1s;
} */

.searchCont {
  display: flex;
  margin: 10px;
  /* position: relative; */
  user-select: none;
  
  /* top: 50%; */
  transform: translate(0, -20%);   
  transition: all 0.3s ease-out;
  z-index: 3;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.preSearchCont {
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  
  /* top: 0;    */
  transform: translate(0, 0);
}

.preSC {
  /* border: 2px solid green; */
  position: absolute;
  top: unset;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate(0,0);
}

.searchCont {
  /* Add styles for searchCont here */
}


.preSearchCont .logo ,
.searchCont .logo {
  width: 45px;
  height: 45px;
  display: flex;
  margin-right: 12px;
  border-radius: 5px; 
}
.preSearchCont .logo{
  width: 250px;
  height: 250px;
}

.preSearchCont .logo > img ,
.searchCont .logo > img{
  width: 100%;
  filter: invert(1);
}

.preSearchCont .searchBox ,
.searchCont .searchBox {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
.preSearchCont .searchBox{
  width: 85%;
  top: -75px;
}

.preSearchCont .searchBox .searchIcon ,
.searchCont .searchBox .searchIcon {
  padding: 10px;
  /* border: 1px solid black; */
  border: none;
  border-left: none;
  display: flex;
  align-items: center;
  color: #888;
  border-radius: 0px 5px 5px 0px;
  height: 70%;
  background-color: white;
}

.preSearchCont .searchBox .searchInput ,
.searchCont .searchBox .searchInput {
  flex: 1;
  padding: 10px;
  border: 1.5px solid white;
  outline: none;
  /* border-radius: 5px 0px 0px 5px; */
  letter-spacing: 0.7px;
  font-family: 'Roboto', sans-serif;
  background-color: transparent;
  color: white;
  height: 26px;
  border-radius: unset;
  user-select: unset;
  /* font-size: 12px; */
  /* don't apply font size here it will creating error in iphone */
}  

.preSearchCont .searchBox .suggestionList ,
.searchCont .searchBox .suggestionList {
  list-style: none;
  /* margin-top: 43px; */
  top: 35px;
  padding: 0;
  position: absolute;  
  width: 100%;
  /* border: 1px solid #ccc;
  border-top: none; */
  border: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 1;

  height: 26px;
  font-size: 11px;
  background-color: black;
  color: white;
}

.preSearchCont .searchBox .suggestionList li ,
.searchCont .searchBox .suggestionList li {
  /* padding: 8px 10px; */
  padding: 5px 7px;
  cursor: pointer;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-family: 'Roboto', sans-serif;
  background-color: black;
}

.preSearchCont .searchBox .suggestionList li:last-child ,
.searchCont .searchBox .suggestionList li:last-child {
  border-bottom: 1px solid #ccc;
}

.preSearchCont .searchBox .suggestionList li:hover ,
.searchCont .searchBox .suggestionList li:hover {
  background-color: rgb(36, 35, 35);
}

.searchCont .searchBox .suggestionList li.selected {
  background-color: rgb(36, 35, 35);
}

.preSearchCont .searchBox .filterCont ,
.searchCont .searchBox .filterCont {
  position: absolute;
  top: 38px;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
  width: 100%;
  padding: 5px 0px;
  /* background-color: rgba(255, 255, 255, 0.871); */
  /* overflow-x: scroll; */
}

.preSearchCont .searchBox .filterCont::-webkit-scrollbar ,
.searchCont .searchBox .filterCont::-webkit-scrollbar {
  width: 0;
} 

.preSearchCont .searchBox .filterCont .selectedFilterCont ,
.searchCont .searchBox .filterCont .selectedFilterCont {
  position: relative;
  display: flex;
  overflow-x: scroll;
  float: left;
}
.preSearchCont .searchBox .filterCont .selectedFilterCont::-webkit-scrollbar ,
.searchCont .searchBox .filterCont .selectedFilterCont::-webkit-scrollbar {
  width: 0;
  display: none;
}

.preSearchCont .searchBox .filterCont .unSelectedFilterCont ,
.searchCont .searchBox .filterCont .unSelectedFilterCont {
  position: relative;
  display: flex;
  float: right;
  overflow-x: scroll;
}
.preSearchCont .searchBox .filterCont .unSelectedFilterCont::-webkit-scrollbar ,
.searchCont .searchBox .filterCont .unSelectedFilterCont::-webkit-scrollbar {
  width: 0;
  display: none;
}

.preSearchCont .searchBox .filterDiv ,
.searchCont .searchBox .filterDiv {
  border-radius: 5px;
  margin: 4px 7px;
  padding: 2px 15px;
  cursor: pointer;
  letter-spacing: 0.3px;
  position: relative;
  /* background-color: #ffffff24; */
  color: white;
  border: 1px solid white;
  text-transform: uppercase;
  font-size: 12px;
}

.preSearchCont .searchBox .filterDiv:hover ,
.searchCont .searchBox .filterDiv:hover {
  background-color: rgba(0, 0, 0, 0.851);
  color: white;
  scale: 1.05;
  transition: all 0.4s;
}

.filterDiv .closeIcon {
  width: 14px;
  top: -5px;
  right: -7px;
  position: absolute;
  transition: transform 0.3s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  background-color: rgb(255 255 255);
  border: 2px solid rgb(19 255 6);
  border-radius: 50%;
  /* filter: invert(1); */
  transform: rotate(45deg);
}

.filterDiv .closeIcon:hover {
  transform: rotate(135deg);
}

.preSearchCont .searchBox .selectedFilterDiv ,
.searchCont .searchBox .selectedFilterDiv {
  border-radius: 5px;
  margin: 4px 7px;
  padding: 2px 10px;
  cursor: pointer;
  letter-spacing: 0.3px;
  position: relative;
  background-color: #ffffff24;
  color: white;
  border: 1px solid white;
  text-transform: uppercase;
  font-size: 12px;
}

.preSearchCont .searchBox .selectedFilterDiv:hover ,
.searchCont .searchBox .selectedFilterDiv:hover {
  background-color: rgba(0, 0, 0, 0.851);
  color: white;
  scale: 1.05;
  transition: all 0.4s;
}

.selectedFilterDiv .closeIcon {
  width: 14px;
  position: relative;
  right: -6px;
  top: -1px;

  /* top: -5px;
  right: -7px;
  position: absolute; */
  transition: transform 0.3s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  /* background-color: rgb(255 255 255); */
  /* border: 2px solid rgb(250, 124, 124); */
  /* border-radius: 50%; */
  /* filter: invert(1); */
  /* transform: rotate(45deg); */
  filter: invert(1);
}

.selectedFilterDiv .closeIcon:hover {
  transform: rotate(90deg);
}

::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: white;
}

@media only screen and (max-width: 450px) {
  .preSearchCont .searchBox .filterDiv ,
  .searchCont .searchBox .filterDiv {
    font-size: 10px;
  }

  .preSearchCont .searchBox .selectedFilterDiv ,
  .searchCont .searchBox .selectedFilterDiv {
    font-size: 10px;
  }

  .preSC {
    /* transform: translate(0, 80vh); */
    /* position: fixed;
    left: 0;
    right: 0; */

    /* transform: unset;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0; */
  }
}