.mainCont {
  /* border: 2px solid white; */
}

.mainCont::after {
 content: '';
 background: url('https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExZTBzbG13YXl2MmhmZzUzZjluMG0wNHpjOXozYnBucnIzamd6aXdkNCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/bcAXnpZhenJA5McqfL/giphy.gif') no-repeat center center fixed;
 background-size: cover;
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 z-index: -1;
 opacity: 0.4;
}

.mainCont .scrollCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  position: absolute;
  height: 88%;
  left: 0;
  right: 0;
  /* border: 2px solid yellow; */
  /* z-index: 4; */
}

.mainCont .vidaGifCont {
  /* border: 2px solid red; */
  height: 100vh;
  width: 100vw;
  mix-blend-mode: lighten;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  overflow: hidden;

  width: auto;
}

.mainCont .vidaGifCont .vidaGif {
  /* border: 2px solid blue; */
  width: auto;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  overflow: hidden;
}


@media only screen and (max-width: 450px) {
  .mainCont .vidaGifCont {
    height: 89vh;
  }
}