.productsScrollView {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 27%;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scroll-behavior: smooth;
    /* gap: 15px; */
    width: 100%;
}

.productsScrollView::-webkit-scrollbar {
  width: 0;
  display: none;
} 

.snaps-inline {
    scroll-snap-type: inline mandatory;
    scroll-padding-inline: 15px;
}

.snaps-inline > * {
    scroll-snap-align: start;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}
 
.mod {
    /* background: #fff; */
    background: #bebebef5;
    /* padding: 30px; */
    border-radius: 8px;
    position: relative;
}

.mod .closeIcon {
    width: 35px;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    user-select: none;
    z-index: 4;
}

.mod .closeIcon:hover {
    transform: rotate(90deg);
}

@media only screen and (max-width: 1024px) {
    .productsScrollView {
        grid-auto-columns: 40%;
    }
}

@media only screen and (max-width: 500px) {
    .productsScrollView {
        grid-auto-columns: 92%;
    }
}
