@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
  /* background-image: url('https://i.giphy.com/3ohhwNqFMnb7wZgNnq.webp'); */
  /* background-image: url('https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExMTcyYTh0aDJpMHc2bTViZG1qMWRvZDVvMWlnc29oZnZzdG5vNGp4ZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/hMwSavXM2XJMbokIvM/giphy.gif'); */
  /* background-image: url('https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExZTBzbG13YXl2MmhmZzUzZjluMG0wNHpjOXozYnBucnIzamd6aXdkNCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/bcAXnpZhenJA5McqfL/giphy.gif'); */
  /* background-size: cover;  */
  /* background-position: center;  */
  /* background-repeat: no-repeat; */
  background: black !important;
}

/* body::after {
 content: '';
 background: url('https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExZTBzbG13YXl2MmhmZzUzZjluMG0wNHpjOXozYnBucnIzamd6aXdkNCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/bcAXnpZhenJA5McqfL/giphy.gif') no-repeat center center fixed;
 background-size: cover;
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 z-index: -1;
 opacity: 0.4;
} */

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
