.toolTip {
    /* position: relative; */
    display: inline-block;
  }
  
  .toolTip .tooltip-text {
    visibility: hidden;
    /* width: 120px; */
    background-color: var(--tooltip-background-color);
    color: var(--tooltip-text-color);
    text-align: center;
    border-radius: 0px 4px 4px 4px;
    padding: 2px 5px;
    text-transform: capitalize;
    position: absolute;
    z-index: 2;
    /* bottom: 12; */
    /* margin-left: -60px; */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .toolTip img {
    /* width: 100px;
    height: auto; */
    width: 15px;
    height: 15px;
    object-fit: contain;
  }
  
  .toolTip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  