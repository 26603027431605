.sizechartCont .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}
 
.sizechartCont .mod {
    /* background: #fff; */
    background: var(--model-background-color);
    /* padding: 30px; */
    border-radius: 8px;
    position: relative;
}

.sizechartCont .mod .closeIcon {
    width: 35px;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    user-select: none;
    z-index: 4;
}

.sizechartCont .mod .closeIcon:hover {
    transform: rotate(90deg);
}

.sizechartCont .sizechartbtn {
    border: none;
    outline: none;
    margin-bottom: 10px;
    margin-left: 0px;
    cursor: pointer;
    border-radius: 3px;
    font-family: "Raleway", sans-serif;
    background-color: var(--btn-background-color);
    color: var(--btn-text-color);
    font-size: var(--btn-font-size);
    display: flex;
    justify-content: center;
    align-items: center;
}

.sizechartCont .sizechartImg {
    max-width: 100%;
    max-height: 100%;
    width: 500px;
    margin: auto;
}

@media only screen and (max-width: 500px) {
    .sizechartCont .sizechartImg {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
}