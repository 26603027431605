.productDetailCardCont {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Roboto', sans-serif;
    /* user-select: none; */
    background: white;

    /* max-height: 35rem; */
    height: 32rem;
}

.productDetailCardCont .imgBox {
    flex: 1;
    /* overflow: hidden; */
    width: 100px; /*dummy due to problem happning on desktop view in img slider*/
}

.productDetailCardCont .imgBox img {
    width: 100%;
    height: 100%;
}

.productDetailCardCont .product-details {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    overflow: auto;
    text-align: justify;
}
.productDetailCardCont .product-details::-webkit-scrollbar {
    width: 0;
}

.productDetailCardCont .product-details .product-title {
    font-size: 1.2rem;
    margin-bottom: 8px;
    font-weight: bold;
}

.productDetailCardCont .product-details .product-description {
    color: #666;
}

.productDetailCardCont .product-details .product-price {
    font-weight: bold;
    margin-top: 3px;
    /* color: #a65877; */
    color: black;
}


.productDetailCardCont .product-details a {
    text-align: center;
}

.productDetailCardCont .product-details .btn {
    font-size: 0.9rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    appearance: none;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    border: none !important;
    cursor: pointer;
    margin: 4px 0px;
    transition: 0.8s ease-in-out;
    position: relative;
}

.productDetailCardCont .product-details .btn1 {
    /* background: rgb(238, 228, 214);
    color: rgb(42, 41, 43); */
    background-color: white;
    color: black;
    border: 1px solid black !important;
    width: 100%;
}

.productDetailCardCont .product-details .btn2 {
    background: #7C3D55;
    color: #EEE4D6;
}

.productDetailCardCont .product-details .btn1:hover {
    /* background: rgb(235, 210, 176); */
    background: #eae8e8;
}

.productDetailCardCont .product-details .btn2:hover {
    background: #a65877;
}

.productDetailCardCont .product-details .variantTitle {
    color: black;
    margin: 5px 0px;
    font-weight: bold;
}

.productDetailCardCont .product-details .listItemCont {
    display: flex;
    width: 100%;
    margin: unset;
    list-style: none;
    padding: unset;
    overflow-x: scroll;
}

.productDetailCardCont .product-details .listItemCont::-webkit-scrollbar {
    width: 0;
  } 

.productDetailCardCont .product-details .listItemCont .listItem {
    display: flex;
    justify-content: center;
    /* min-width: 56px; */
    padding: 11px 18px 13px;
    margin-right: 4px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 4px;
    background: rgb(255, 255, 255);
    color: rgb(26, 26, 26);
    text-align: center;
    cursor: pointer;
    transition: background .2s ease;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}

.productDetailCardCont .product-details .listItemCont .listItem:hover {
    border: 1px solid rgb(190, 189, 189);
    border-radius: 4px;
    /* background: #f5f1ec; */
    background: #eae8e8;
}

.productDetailCardCont .product-details .listItemCont .selectedSize {
    border: 1px solid rgb(190, 189, 189);
    border-radius: 4px;
    /* background: #f5f1ec; */
    background: #eae8e8;
}

@media only screen and (max-width: 950px) {
    .productDetailCardCont {
        flex-direction: column;
        max-width: 90vw;
        max-height: 80vh;
        overflow-y: scroll;

        height: unset;
    }
    .productDetailCardCont .imgBox {
        /* overflow: scroll; */
        width: unset;
    }
    .productDetailCardCont .product-details {
        overflow: unset;
    }
}