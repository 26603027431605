@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --filter: invert(1);
  --chat-background-img: url("https://i.pinimg.com/564x/33/7a/6a/337a6aa280e250c703bc3a4346466f05.jpg");

  --main-background-color: white;
  --header-background-color: black;
  --userMsgDiv-background-color: #8080808a;
  --botMsgDiv-background-color: #000000a3;
  --input-background-color: #000000;
  --option-background-color: #fbfbfb77;
  --option-hovered-background-color: #80808085;
  --option-title-background-color: #f7f7f7;
  --option-title-hovered-background-color: #f7f7f7;
  --newFeature-background-color: #f3dbe596;
  --newFeature-hovered-background-color: #dbe5f3;
  --model-background-color: #bebebef5;
  --btn-background-color: black;
  --btn-hovered-background-color: white;
  --tooltip-background-color: black;
  --card-que-background-color: #ffffff24;
  --card-opt-background-color: #ffffff24;
  --card-opt-hovered-background-color: #ffffff42;
  --price-tag-background-color: #7c3d55d8;
  --product-detail-background-color: white;
  --pdc-size-background-color: rgb(255, 255, 255);
  --pdc-size-hovered-background-color: #eae8e8;
  
  --text-color: white;
  --text-color2: black;
  --userMsgDiv-text-color: black;
  --botMsgDiv-text-color: white;
  --tag-text-color: #000000c7;
  --input-text-color: rgba(255, 255, 255, 0.882);
  --input-placeholder-text-color: #636060;
  --option-text-color: black;
  --btn-text-color: white;
  --btn-hovered-text-color: black;
  --tooltip-text-color: white;
  --card-que-text-color: black;
  --card-opt-text-color: white;
  --nudge-text-color: black;
  --pdc-text-color: #666;
  --pdc-size-text-color: rgb(26, 26, 26);
  
  --header-border-color: none;
  --msg-border-color: none;
  --option-border-color: #2e2e2e59;
  --option-hovered-border-color: #eeeeee;
  --card-border-color: rgba(222, 219, 219, 0.57);
  --card-hovered-border-color: #8e8e8c80;
  --product-detail-card-border-color: #ccc;
  --btn-border-color: white;
  --btn-hovered-border-color: black;
  --pdc-size-border-color: rgb(221, 221, 221);
  --pdc-size-hovered-border-color:  rgb(190, 189, 189);
  --input-border-color: none;

  --card-boxshadow: rgba(80, 77, 77, 0.2);
  --product-detail-card-boxshadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  --main-font: "Noto Sans", sans-serif;
  --heading-font: "Noto Sans", sans-serif;
  --msg-font: "Noto Sans", sans-serif;
  --option-font: 'Roboto', sans-serif;
  --card-que-font: 'Roboto', sans-serif;
  --product-detail-font: 'Roboto', sans-serif;
  --product-title-font: 'Roboto', sans-serif;
  
  --tag-font-size: 12px;
  --heading-font-size: 16px;
  --option-font-size: 16px;
  --option-title-font-size: 13px;
  --msgDiv-font-size: 16px;
  --btn-font-size: 12px;
  --card-que-title-font-size: 13px;
  --nudge-font-size: 8px;
  --product-detail-font-size: 13px;
  --product-price-font-size: 10px;
  --product-aitag-font-size: 12px;
  --pdc-title-font-size: 1.2rem;
  --pdc-detail-font-size: 0.9rem;

  --header-padding: 20px;
  --reset-icon-size: 28px;
  --vida-img-size: 32px;
  --logo-size: 59px;
  --loading-color: #ff999977;

  --product-title-font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--main-font);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 500px) {
  :root{
    --option-font-size: 14px;
    --msgDiv-font-size: 14px;
    --btn-font-size: 10px;
  }
}