.productCardCont {
    border: 1px solid rgba(222, 219, 219, 0.57);
    border-radius: 8px;
    /* overflow: hidden; */
    cursor: pointer;
    /* box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 2px 20px rgba(80, 77, 77, 0.2);
    margin: 5px 15px;
    padding: 12px;
    position: relative;
    transition: 0.2s ease-out;

    /* display: grid;
    grid-template-rows: min-content; */
    /* display: flex;
    flex-direction: column; */
    user-select: none;
}

.productCardCont:hover {
    border: 1.5px solid #8e8e8c80;
}

.productCardCont img {
    width: 100%;
    height: auto;
    aspect-ratio: 3/4;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    user-select: none;
}

.productCardCont .priceCont {
    position: absolute;
    background-color: #7c3d55d8;
    color: white;
    border-radius: 3px;
    padding: 2px 5px;
    top: 20px;
    right: 20px;
}

.productCardCont .product-details {
    user-select: none;
}

.productCardCont .product-details .nudge{
    /* background-color: #000000ad; */
    /* background-color: #000000;
    color:white; */
    /* background-color: #f4f0f1; */
    color:black !important;
    padding: 2px 5px;
    margin: 2px;
    border-radius: 2px;
    color: white;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 8px;
}

.productCardCont .product-details .product-title {
    border-radius: 0px 0px 8px 8px; 
    /* background-color: #f5f5f5e0; */
    background-color: white;
    /* color: #7c3d55d8; */
    /* font-weight: bold; */
    padding: 4px;
    font-size: 1rem;
    margin: 0;
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
    white-space: nowrap;

    overflow-x: scroll;
    display: flex;
    font-size: 13px;
}
.productCardCont .product-details .product-title::-webkit-scrollbar {
    width: 0;
    display: none;
}