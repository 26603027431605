.chatBotMC {
    /* height: 100vh; */
    /* background-color: white; */
    /* border: 2px solid red; */
    background-color: var(--main-background-color);
    position: relative;
}

.chatBotMC::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  /* background: url("../../assets/chatBg.png"); */
  /* background: url("https://i.pinimg.com/564x/ef/92/4e/ef924e85d47580b665bfbd5142fb33a4.jpg"); */
  /* background: url("https://i.pinimg.com/564x/33/7a/6a/337a6aa280e250c703bc3a4346466f05.jpg"); */
  background: var(--chat-background-img);
  /* background: url("https://www.interviewmagazine.com/wp-content/uploads/2020/09/Interview_digital_web_2020_sept_KennyEdem_10.jpg"); */
  /* background: url("https://cdn.magzter.com/1357655155/1648540416/articles/sLcS1uUfc1650530046878/The-HERMES-PRINCIPLE.jpg"); */
  /* z-index: -1; */
  background-size: cover;
  background-position: center;
  
}

/* .chatBotMC::after {
  content: '';
  background: white;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
 } */

.chatBotMC .headingDiv {
    display: flex;
    text-align: center;
    /* font-family: 'Roboto', sans-serif; */
    font-family: var(--heading-font);
    background-color: var(--header-background-color);
    border: var(--header-border-color);
    /* padding: 20px; */
    padding: var(--header-padding);
    /* margin-bottom: 15px; */
    position: relative;
    color: var(--text-color);
    border-radius: 0px;
    align-items: center;
}

.chatBotMC .headingDiv .logo {
  width: var(--logo-size);
  /* width: 59px; */
  /* height: 29px; */
  height: auto;
  margin-right: 12px;
  margin-left: 4px;
}

.chatBotMC .headingDiv .logo > img{
  width: 100%;
  /* height: 100%; */
  filter: var(--filter);
}

.chatBotMC .headingDiv .resetIconDiv {
  /* width: 24px;
  height: 30px;  */
  width: var(--reset-icon-size);
  height: var(--reset-icon-size); 
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.chatBotMC .headingDiv .resetIconDiv > img{
  width: 100%;
  filter: var(--filter);
}

/* @media only screen and (max-height: 740px) {
    .chatBotMC .headingDiv {
        padding: 10px;
    }
}

@media only screen and (min-width: 550px) {
  .chatBotMC .headingDiv {
    padding: 10px;
  }
} */

@media only screen and (min-width: 600px) {
  .chatBotMC {
    width: 550px;
    margin: auto;
    box-shadow: 0px 2px 20px rgba(80, 77, 77, 0.2);
  }
}