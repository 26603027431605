.productCardCont {
    border: 1px solid var(--card-border-color);
    border-radius: 8px;
    /* overflow: hidden; */
    cursor: pointer;
    /* box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 1px 1px var(--card-boxshadow);
    margin: 5px 6px;
    /* padding: 12px; */
    position: relative;
    transition: 0.2s ease-out;

    /* display: grid;
    grid-template-rows: min-content; */
    /* display: flex;
    flex-direction: column; */
    user-select: none;
    background-color: white;
}

.productCardCont:hover {
    border: 1.5px solid var(--card-hovered-border-color);
}

.productCardCont img {
    width: 100%;
    height: auto;
    aspect-ratio: 3/4;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
    user-select: none;
}

.productCardCont .priceCont {
    position: absolute;
    background-color: var(--price-tag-background-color);
    color: var(--text-color);
    border-radius: 3px;
    padding: 2px 5px;
    top: 5px;
    right: 5px;
}

.productCardCont .product-details {
    user-select: none;
}

.productCardCont .product-details .nudge{
    /* background-color: #000000ad; */
    /* background-color: #000000;
    color:white; */
    /* background-color: #f4f0f1; */
    /* color:var(--nudge-text-color) !important; */
    padding: 2px 5px;
    margin: 2px;
    border-radius: 2px;
    color: var(--nudge-text-color);
    white-space: nowrap;
    text-transform: uppercase;
    font-size: var(--nudge-font-size);
}

.productCardCont .product-details .nudge::-webkit-scrollbar {
    width: 0;
    display: none;
}

.productCardCont .product-details .product-title-cont {
    border-radius: 0px 0px 8px 8px; 
    /* background-color: #f5f5f5e0; */
    background-color: var(--product-detail-background-color);
    /* color: #7c3d55d8; */
    /* font-weight: bold; */
    padding: 4px;
    /* font-size: 1rem; */
    margin: 0;
    /* font-family: 'Montserrat', sans-serif; */
    font-family: var(--product-detail-font);
    letter-spacing: 0.5px;
    white-space: nowrap;

    /* overflow-x: scroll; */
    /* display: flex; */
    font-size: var(--product-detail-font-size);
}

.productCardCont .product-details .product-title {
    display: flex;
    overflow-x: scroll;
    font-family: var(--product-title-font);
    font-style: var(--product-title-font-style);
}

.productCardCont .product-details .product-title::-webkit-scrollbar {
    width: 0;
    display: none;
}

.productCardCont .product-details .price-div {
    display: flex;
    font-size: var(--product-price-font-size);
    align-items: center;
}

.productCardCont .product-details .aitag {
    display: flex;
    overflow-x: scroll;
    font-size: var(--product-aitag-font-size);
    align-items: center;
}

.productCardCont .product-details .aitag::-webkit-scrollbar {
    width: 0;
    display: none;
}